import * as React from "react";
import {
  Theme,
  ThemePanel,
  Container,
  Section,
  Grid,
  Flex,
  TextField,
  Heading,
  IconButton,
  Button,
  Text,
  Tooltip,
  Spinner,
  Box,
  Card,
  Select,
  Blockquote,
  Switch,
  TextArea,
  Callout,
  Dialog,
  Separator,
  Strong,
  Checkbox,
} from "@radix-ui/themes";
import {
  StarIcon,
  ExclamationTriangleIcon,
  EyeClosedIcon,
  InfoCircledIcon,
  MagnifyingGlassIcon,
  Share2Icon,
  EnvelopeOpenIcon,
  PaperPlaneIcon,
  LockClosedIcon,
  CheckIcon,
  FaceIcon,
} from "@radix-ui/react-icons";
import Combobox1 from "./Combobox1";
import { useEffect, useState } from "react";
import { Label } from "@radix-ui/themes/dist/cjs/components/context-menu";
import { useTelegram } from "../hooks/useTelegram";
import {
  createDrive,
  createNotifySignRequest,
  createPassangerDriverRequest,
  createTransferBotRequest,
  getAllRoutes,
  joinDrive,
  searchDrives,
} from "../api/api";
import { IDrive } from "./types";
import * as petIcon from "../imgs/pet-iocn.svg";
import * as kidIcon from "../imgs/kid-icon.svg";
import { getISODateTime, getLocalTime } from "../utils/timeUtils";

// const getLocalTime = (datetime: string) => {
//   let timeAsTimestamp =
//     new Date(datetime).getTime() + 4 *60*60*1000;

//   return new Date(timeAsTimestamp).toLocaleTimeString("ru").slice(0, 5);
// };

const getRoutes = (
  all_routes: {
    from: string;
    to: string;
    price: number;
  }[],
  a_place: string
) => {
  let all_places_form_a: string[] = [];

  for (let i = 0; i < all_routes.length; i++) {
    const route = all_routes[i];
    if (route.from.toLowerCase() == a_place.toLowerCase())
      all_places_form_a.push(route.to);

    // if (route.to.toLowerCase() == a_place.toLowerCase())
    //   all_places_form_a.push(route.from);
  }

  return all_places_form_a;
};

const getRoutePrice = (
  all_routes: {
    from: string;
    to: string;
    price: number;
  }[],
  from: string,
  to: string
) => {
  return all_routes.find((r) => {
    return (r.from == from && r.to == to) || (r.to == from && r.from == to);
  })?.price;
};

const getAllPlaces = (
  all_routes: {
    from: string;
    to: string;
    price: number;
  }[]
) => {
  let routes = [
    ...all_routes.map((r) => r.from),
    ...all_routes.map((r) => r.to),
  ];

  return [...new Set(routes)];
};

// export function getISODateTime(date: string, time: string) {
//   let t = time.split(":");

//   let t_seconds = parseInt(t[0]) * 60 * 60 + parseInt(t[1]) * 60;

//   return new Date(
//     new Date(new Date(date)).getTime() + t_seconds * 1000 - 4*60*60*2
//   ).toISOString();
// }

export default function SearchDrives() {
  const { user, webApp } = useTelegram();
  const tg = webApp;

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [date, setDate] = useState("");

  const [all_routes, setAll_routes] = useState<
    {
      from: string;
      to: string;
      price: number;
    }[]
  >([]);

  const [allPlaces, setAllPlaces] = useState<string[]>([]);
  const [fromPlaces, setFromPlaces] = useState<string[]>([]);
  const [toPlaces, setToPlaces] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [drives, setDrives] = useState<IDrive[] | undefined>();

  const [isDateRange, setIsDateRange] = useState(false);
  const [dateRangeBegin, setDateRangeBegin] = useState("");
  const [dateRangeFinish, setDateRangeFinish] = useState("");

  const handleChangePlaces = (place: string, isFrom: boolean) => {
    const routes = getRoutes(all_routes, place);

    if (isFrom) {
      setFromPlaces(allPlaces);
      setToPlaces(routes);
      setFrom(place);

      return;
    }

    // if (!(from in routes))
    //     setFrom("")

    setToPlaces(allPlaces);
    setFromPlaces(routes);
    setTo(place);
  };

  const getArr = (isFrom: boolean) => {
    if (isFrom && to.length > 0) return getRoutes(all_routes, to);
    else if (!isFrom && from.length > 0) return getRoutes(all_routes, from);

    return allPlaces;
  };

  useEffect(() => {
    getAllRoutes().then((res) => {
      const routes = res.data.routes;
      setAll_routes(routes);
      const all = getAllPlaces(routes);
      setAllPlaces(all);
      setFromPlaces(all);
      setToPlaces(all);
    });
  }, []);

  const isData = from.length > 0 && to.length > 0 && date.length > 0;

  const handleSearch = async () => {
    setIsLoading(true);

    try {
      console.log(date);
      const res = await searchDrives(from, to, date);
      if (res.data.drives) setDrives(res.data.drives);
    } catch (err) {}
    setIsLoading(false);
  };

  const handleJoinDrive = async (driveId: number) => {
    console.log("driveId", driveId);
    const query_id = webApp?.initDataUnsafe.query_id;
    if (!query_id || !user?.id) return;
    console.log("query_id", query_id);

    const res = await joinDrive(query_id, {
      userId: user?.id.toString(),
      driveId,
    });

    console.log("res", res);

    if (tg) tg.close();
  };

  const handlePassangerRequst = async () => {
    const query_id = webApp?.initDataUnsafe.query_id;
    if (!query_id || !user?.id) return;
    if (dateRangeBegin > date) return;

    const hasRange = dateRangeBegin != "" && dateRangeFinish != "";

    if (hasRange && dateRangeBegin == dateRangeFinish)
      return;

    const res = await createNotifySignRequest(query_id, {
      userId: user?.id.toString(),
      from,
      to,
      datetime: getISODateTime(date, "01:00"),
      isDateRange,
      dateFrom: hasRange ? getISODateTime(dateRangeBegin, "01:00") : undefined,
      dateTo: hasRange ? getISODateTime(dateRangeFinish, "01:00") : undefined,
    });

    console.log(res);

    if (tg) tg.close();
  };

  
  const handleTransferRequst = async () => {
    const query_id = webApp?.initDataUnsafe.query_id;
    if (!query_id || !user?.id) return;

    const res = await createTransferBotRequest(query_id, {
      userId: user?.id.toString()
    });

    if (tg) tg.close();
  };


  return (
    <Box m="4" mt="0" pt="4">
      <Card
        size="3"
        style={{
          contain: "unset",
          overflow: "unset",
          backgroundColor: "var(--amber-4)",
        }}
      >
        <Heading size="3">Найти поездку</Heading>
        <Flex mt="4" direction="column" gap="4">
          <Blockquote mb="2">
            <Flex direction="column" gap="2">
              <Combobox1
                getList={() => getArr(true)}
                placeholder="Откуда"
                value={from}
                arg={true}
                setValue={handleChangePlaces}
              />
              <Combobox1
                getList={() => getArr(false)}
                placeholder="Куда"
                value={to}
                arg={false}
                setValue={handleChangePlaces}
              />
            </Flex>
          </Blockquote>
          <TextField.Root
            mt="-2"
            size="3"
            placeholder="Date"
            type="date"
            min={new Date().toISOString().slice(0, 10)}
            onChange={(e) => setDate(e.target.value)}
          />
          <Button
            disabled={!isData || isLoading}
            loading={isLoading}
            size="3"
            onClick={handleSearch}
          >
            Найти
          </Button>
        </Flex>
      </Card>
      <Flex mt="4" minWidth={"100%"} gap="4" direction={"column"}>
        {drives != undefined && (
          <>
            <Separator mt="4" size="4" />
            {drives.length > 0 ? (
              <>
                {drives
                  .filter(
                    (drive) => drive._count.passsangers < drive.passengersCount
                  )
                  .map((drive, i) => {
                    return (
                      <Card size="3" key={i}>
                        <Flex align={"center"} justify="between">
                          <Box minWidth="60%">
                            <Text
                              color="grass"
                              weight="bold"
                              mb="2"
                              as="div"
                              size="6"
                            >
                              {drive.price} TL
                            </Text>
                            <Flex align={"center"}>
                              <Text weight="bold" as="span">
                                {drive.driver.userInfo.name
                                  .charAt(0)
                                  .toUpperCase() +
                                  drive.driver.userInfo.name.slice(1)}
                              </Text>
                              <Separator
                                ml="2"
                                mr="2"
                                size="1"
                                orientation="vertical"
                              />
                              <Text as="span">
                                {getLocalTime(drive.datetime)}
                              </Text>

                              {drive.isThereKids && (
                                <>
                                  <Separator
                                    ml="2"
                                    mr="2"
                                    size="1"
                                    orientation="vertical"
                                  />
                                  <Tooltip content="Можно с детьми">
                                    <kidIcon.ReactComponent />
                                  </Tooltip>
                                </>
                              )}
                              {drive.isThereAnimals && (
                                <>
                                  <Separator
                                    ml="2"
                                    mr="2"
                                    size="1"
                                    orientation="vertical"
                                  />
                                  <Tooltip content="Можно с животными">
                                    <petIcon.ReactComponent />
                                  </Tooltip>
                                </>
                              )}
                            </Flex>
                          </Box>
                          <Flex
                            asChild
                            direction={"column"}
                            align={"center"}
                            justify={"center"}
                          >
                            <Box minHeight={"100%"}>
                              <Dialog.Root>
                                <Dialog.Trigger>
                                  <Button size="3">Подробнее</Button>
                                </Dialog.Trigger>
                                <Dialog.Content>
                                  <Dialog.Title>Детали поездки</Dialog.Title>

                                  <Text size={"5"}>
                                    <Flex
                                      direction={"column"}
                                      align={"baseline"}
                                      m={"2"}
                                      pb="4"
                                      gap={"3"}
                                    >
                                      <Text>
                                        <Text color="gray" weight="light">
                                          Время:{" "}
                                        </Text>

                                        <Text weight={"medium"}>
                                          {getLocalTime(drive.datetime)}
                                        </Text>
                                      </Text>

                                      <Text>
                                        <Text color="gray" weight="light">
                                          Водитель:{" "}
                                        </Text>

                                        <Text weight={"bold"}>
                                          {drive.driver.userInfo.name
                                            .charAt(0)
                                            .toUpperCase() +
                                            drive.driver.userInfo.name.slice(1)}
                                        </Text>
                                      </Text>

                                      <Box>
                                        <Text color="gray" weight="light">
                                          Цена:{" "}
                                        </Text>

                                        <Text
                                          color="grass"
                                          weight="bold"
                                          mb="2"
                                          size="5"
                                        >
                                          {drive.price} TL{" "}
                                          <Text size={"2"} color="gray">
                                            / чел
                                          </Text>
                                        </Text>
                                      </Box>
                                    </Flex>
                                  </Text>
                                  <Flex
                                    direction={"column"}
                                    gap={"3"}
                                    mt="2"
                                    mb="4"
                                  >
                                    <Blockquote>
                                      <Text color="gray" weight="light">
                                        Вместимость сумок:{" "}
                                      </Text>
                                      <Text weight={"medium"}>
                                        {drive.bagsCount}
                                      </Text>
                                    </Blockquote>
                                    <Blockquote>
                                      <Text color="gray" weight="light">
                                        Свободных мест:{" "}
                                      </Text>
                                      <Text weight={"medium"}>
                                        {drive.passengersCount -
                                          drive._count.passsangers}
                                      </Text>
                                    </Blockquote>
                                    <Blockquote>
                                      <Text color="gray" weight="light">
                                        {" Можно собак: "}
                                      </Text>
                                      {drive.isThereAnimals ? (
                                        <Text color="grass" weight={"medium"}>
                                          Да
                                        </Text>
                                      ) : (
                                        <Text color="red" weight={"medium"}>
                                          Нет
                                        </Text>
                                      )}
                                    </Blockquote>
                                    <Blockquote>
                                      <Text color="gray" weight="light">
                                        {" Можно детей: "}
                                      </Text>
                                      {drive.isThereKids ? (
                                        <Text color="grass" weight={"medium"}>
                                          Да
                                        </Text>
                                      ) : (
                                        <Text color="red" weight={"medium"}>
                                          Нет
                                        </Text>
                                      )}
                                    </Blockquote>

                                    {drive.description.length > 0 && (
                                      <Callout.Root mt="2">
                                        <Callout.Icon>
                                          <InfoCircledIcon />
                                        </Callout.Icon>
                                        <Callout.Text>
                                          {drive.description}
                                        </Callout.Text>
                                      </Callout.Root>
                                    )}
                                  </Flex>

                                  <Dialog.Description size="3">
                                    Вы хотиете присоединиться к поездке?
                                  </Dialog.Description>

                                  <Flex gap="3" mt="4" justify="end">
                                    <Dialog.Close>
                                      <Button
                                        size="3"
                                        variant="soft"
                                        color="gray"
                                      >
                                        Отмена
                                      </Button>
                                    </Dialog.Close>
                                    <Dialog.Close>
                                      <Button
                                        size="3"
                                        variant="solid"
                                        onClick={() =>
                                          handleJoinDrive(drive.id)
                                        }
                                      >
                                        Присоединиться
                                      </Button>
                                    </Dialog.Close>
                                  </Flex>
                                </Dialog.Content>
                              </Dialog.Root>
                            </Box>
                          </Flex>
                        </Flex>
                      </Card>
                    );
                  })}
              </>
            ) : (
              <>
                <Card size="3">
                  <Flex gap="2" direction={"column"}>
                    <Text weight="bold" mb="2" as="div" size="3">
                      Ничего не найдено
                    </Text>
                    <Dialog.Root>
                      <Dialog.Trigger>
                        <Button size="3">Уведомить меня о поездке</Button>
                      </Dialog.Trigger>

                      <Dialog.Content style={{overflow: "visible"}}>
                        <Dialog.Title>
                        Подпишитесь на уведомления о новых поездках
                        </Dialog.Title>
                        <Dialog.Description size="2" mb="2">
                            Как только появится новая поездка по вашим критериям (дата или диапазон дат, направление), {" "}
                            бот автоматически отправит вам уведомление.
                        </Dialog.Description>
                        <Text size="2" style={{ opacity: "0.6" }}>
                        Обратите внимание: если вы активируете уведомления более чем на три поездки, {" "}
                        уведомление для самой ранней из них будет автоматически удалено.
                        </Text>

                        <Blockquote mt={"2"} mb="2">
                          <Flex direction="column" gap="2">
                            <Combobox1
                              getList={() => getArr(true)}
                              placeholder="Откуда"
                              value={from}
                              arg={true}
                              setValue={handleChangePlaces}
                            />
                            <Combobox1
                              getList={() => getArr(false)}
                              placeholder="Куда"
                              value={to}
                              arg={false}
                              setValue={handleChangePlaces}
                            />
                          </Flex>
                        </Blockquote>

                        <Box
                          mt="4"
                          pr={{
                            md: "0",
                            xs: "40%",
                          }}
                        >
                          <Flex direction="row" gap="4" justify="between">
                            <Label>Даипозон дат</Label>
                            <Switch
                              checked={isDateRange}
                              size="3"
                              onCheckedChange={(e) => {
                                setIsDateRange(!isDateRange);
                              }}
                            />
                          </Flex>
                          {isDateRange && (
                            <Flex asChild direction="column" gap="3" mt="4">
                              <Blockquote mb="2">
                                <Flex direction="row" gap="4" justify="between">
                                  <Label>От:</Label>
                                  <TextField.Root
                                    mt="-2"
                                    size="3"
                                    placeholder="Date"
                                    type="date"
                                    value={dateRangeBegin}
                                    // max={date}
                                    style={{ maxWidth: "auto" }}
                                    onChange={(e) =>
                                      setDateRangeBegin(e.target.value)
                                    }
                                  />
                                </Flex>

                                <Flex direction="row" gap="4" justify="between">
                                  <Label>До:</Label>
                                  <TextField.Root
                                    mt="-2"
                                    size="3"
                                    placeholder="Date"
                                    type="date"
                                    value={dateRangeFinish}
                                    min={date}
                                    onChange={(e) =>
                                      setDateRangeFinish(e.target.value)
                                    }
                                  />
                                </Flex>
                              </Blockquote>
                            </Flex>
                          )}
                        </Box>
                        <Flex gap="3" mt="4" justify="end">
                          <Dialog.Close>
                            <Button size="3" variant="soft" color="gray">
                              Отмена
                            </Button>
                          </Dialog.Close>
                          <Dialog.Close>
                            <Button
                              size="3"
                              onClick={() => handlePassangerRequst()}
                            >
                              Подписаться
                            </Button>
                          </Dialog.Close>
                        </Flex>
                      </Dialog.Content>
                    </Dialog.Root>
                    <Dialog.Root>
                      <Dialog.Trigger>
                        <Button size="3">Хочу заказать трансфер</Button>
                      </Dialog.Trigger>

                      <Dialog.Content style={{overflow: "visible"}}>
                        <Dialog.Title>
                        Заказаз трансфера
                        </Dialog.Title>
                        <Dialog.Description size="2" mb="2">
                            Вы можете заказать индивидуальный трансфер по любому направлению на удобное вам время {" "}
                            через другой наш бот.
                        </Dialog.Description>

                        <Flex gap="3" mt="4" justify="end">
                          <Dialog.Close>
                            <Button size="3" variant="soft" color="gray">
                              Отмена
                            </Button>
                          </Dialog.Close>
                          <Dialog.Close>
                            <Button
                              size="3"
                              onClick={() => handleTransferRequst()}
                            >
                              Перейти в бота
                            </Button>
                          </Dialog.Close>
                        </Flex>
                      </Dialog.Content>
                    </Dialog.Root>
                  </Flex>
                </Card>
              </>
            )}
          </>
        )}
      </Flex>
    </Box>
  );
}
