


export function getISODateTime(date: string, time: string) {
    let t = time.split(":");
    
    let seconds = parseInt(t[0]) * 60 * 60 + parseInt(t[1]) * 60;
    const dateUTC = new Date(date+"T00:00:00Z")
    
    return new Date(dateUTC.getTime() + seconds * 1000).toISOString();
  }
  
  
export const getLocalTime = (datetime: string) => {
    return datetime.slice(11, 16);
  };
  

// const TIME_OFFSET_SEC = 0 // 4*60*60*1000

// export function getISODateTime(date: string, time: string) {
//     let t = time.split(":");
  
//     let t_seconds = parseInt(t[0]) * 60 * 60 + parseInt(t[1]) * 60;
  
//     return new Date(
//       new Date(new Date(date)).getTime() + t_seconds * 1000 //- TIME_OFFSET_SEC
//     ).toISOString();
//   }

  
// export const getLocalTime = (datetime: string) => {
//     let timeAsTimestamp =
//       new Date(datetime).getTime() // + TIME_OFFSET_SEC;
  
//     return new Date(timeAsTimestamp).toLocaleTimeString("ru").slice(0, 5);
//   };
  