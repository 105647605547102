import React from 'react';
import logo from './logo.svg';
import './App.css';
import { TelegramProvider, useTelegram } from './hooks/useTelegram';
import { Theme } from '@radix-ui/themes';
import DriverForm from './pages/DriverForm';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import VerifyForm from './pages/VerifyForm';
import SearchDrives from './pages/SearchDrives';

function App() {
  const {webApp} = useTelegram()
  return (
  
  <TelegramProvider>
    <Theme
      accentColor="amber"
      appearance={"light"}
      grayColor="mauve"
      radius="large"
      hasBackground={false}
    >
      <BrowserRouter>
      <Routes>
            <Route path="*" element={<h1>404 Error</h1>} />
            <Route path='/verify' element={<VerifyForm />} />
            <Route path='/create_drive' element={<DriverForm />} />
            <Route path='/search_drive' element={<SearchDrives />} />
      </Routes>
      
      </BrowserRouter>
      {/* <DriverForm /> */}
      {/* <Combobox /> */}
    </Theme>
  </TelegramProvider>
  );
}

export default App;
