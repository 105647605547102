import { startTransition, useEffect, useMemo, useState } from "react";
import * as Ariakit from "@ariakit/react";
import { matchSorter } from "match-sorter";
import "./style.css";

const list1 = ["Море", "Горы"];

export default function Combobox1(props: {
  getList: () => string[]
  placeholder: string;
  value: string;
  setValue: (value: string, arg: any) => void;
  arg: any
}) {

  const matches = () => matchSorter(props.getList(), props.value);

  return (
    <>
      <Ariakit.ComboboxProvider
      defaultValue={props.value}
        setValue={(value) => {
          startTransition(() => {
            props.setValue(value, props.arg)
          });
        }}
      >
        <Ariakit.Combobox
          placeholder={props.placeholder}
          className="combobox"
        />
        <Ariakit.ComboboxPopover gutter={8} sameWidth className="popover">
          {
            matches().length ? (
              matches().map((value) => (
                <Ariakit.ComboboxItem
                  key={value}
                  value={value}
                  className="combobox-item"
                />
            ))
          ) : (
            <div className="no-results">Нет результатов</div>
          )}
        </Ariakit.ComboboxPopover>
      </Ariakit.ComboboxProvider>
    </>
  );
}
