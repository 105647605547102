import * as React from "react";
import {
  Theme,
  ThemePanel,
  Container,
  Section,
  Grid,
  Flex,
  TextField,
  Heading,
  IconButton,
  Button,
  Text,
  Tooltip,
  Spinner,
  Box,
  Card,
  Select,
  Blockquote,
  Switch,
  TextArea,
  Callout,
  AlertDialog,
  Dialog,
} from "@radix-ui/themes";
import {
  StarIcon,
  ExclamationTriangleIcon,
  EyeClosedIcon,
  InfoCircledIcon,
  MagnifyingGlassIcon,
  Share2Icon,
  EnvelopeOpenIcon,
  PaperPlaneIcon,
  LockClosedIcon,
  CheckIcon,
} from "@radix-ui/react-icons";
import Combobox1 from "./Combobox1";
import { useEffect, useState } from "react";
import { Label } from "@radix-ui/themes/dist/cjs/components/context-menu";
import { useTelegram } from "../hooks/useTelegram";
import { createDrive, getAllRoutes } from "../api/api";
import { getISODateTime } from "../utils/timeUtils";

const getRoutes = (
  all_routes: {
    from: string;
    to: string;
    price: number;
  }[],
  a_place: string
) => {
  let all_places_form_a: string[] = [];

  for (let i = 0; i < all_routes.length; i++) {
    const route = all_routes[i];
    if (route.from.toLowerCase() == a_place.toLowerCase())
      all_places_form_a.push(route.to);

    // if (route.to.toLowerCase() == a_place.toLowerCase())
    //   all_places_form_a.push(route.from);
  }

  return all_places_form_a;
};

const getRoutePrice = (
  all_routes: {
    from: string;
    to: string;
    price: number;
  }[],
  from: string,
  to: string
) => {
  return all_routes.find((r) => {
    return (r.from == from && r.to == to) || (r.to == from && r.from == to);
  })?.price;
};

const getAllPlaces = (
  all_routes: {
    from: string;
    to: string;
    price: number;
  }[]
) => {
  let routes = [
    ...all_routes.map((r) => r.from),
    ...all_routes.map((r) => r.to),
  ];

  return [...new Set(routes)];
};

// export function getISODateTime(date: string, time: string) {
//   let t = time.split(":");

//   let t_seconds = parseInt(t[0]) * 60 * 60 + parseInt(t[1]) * 60;

//   return new Date(
//     new Date(new Date(date)).getTime() + t_seconds * 1000 - 4*60*60*1000
//   ).toISOString();
// }

export default function DriverForm() {
  const { user, webApp } = useTelegram();
  const tg = webApp;

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [passangersCount, setPassangersNumber] = useState("");
  const [bagsCount, setBagsNumber] = useState("");
  const [animalsAlowed, setAnimalsAlowed] = useState(false);
  const [kidsAlowed, setKidsAlowed] = useState(false);
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(0);
  const [recomendedCost, setRecomendedCost] = useState(500);

  const [all_routes, setAll_routes] = useState<
    {
      from: string;
      to: string;
      price: number;
    }[]
  >([]);
  const [allPlaces, setAllPlaces] = useState<string[]>([]);
  const [fromPlaces, setFromPlaces] = useState<string[]>([]);
  const [toPlaces, setToPlaces] = useState<string[]>([]);

  const [currentOpen, setCurrentOpen] = useState(0);
  const [lastStep, setLastStep] = useState(-1);

  const [result, setResult] = useState<{ error: boolean } | undefined>(
    undefined
  );

  const handleChangePlaces = (place: string, isFrom: boolean) => {
    const routes = getRoutes(all_routes, place);

    if (isFrom) {
      setFromPlaces(allPlaces);
      setToPlaces(routes);
      setFrom(place);

      return;
    }

    // if (!(from in routes))
    //     setFrom("")

    setToPlaces(allPlaces);
    setFromPlaces(routes);
    setTo(place);
  };

  useEffect(() => {
    getAllRoutes().then((res) => {
      const routes = res.data.routes;
      setAll_routes(routes);
      const all = getAllPlaces(routes);
      setAllPlaces(all);
      setFromPlaces(all);
      setToPlaces(all);
    });
  }, []);

  useEffect(() => {
    // console.log(from.length, to.length, time.length, date.length);
    const allPlaces = getAllPlaces(all_routes);

    if (
      allPlaces.includes(from) &&
      allPlaces.includes(to) &&
      time.length > 3 &&
      date.length > 3
    ) {
      setLastStep(0);
      setCurrentOpen(1);

      if (passangersCount.length) {
        setLastStep(1);
        setCurrentOpen(2);

        if ((price || 0) > 0) setLastStep(2);
      }
    }
  }, [from, to, time, date, passangersCount, price]);

  const onSendData = async () => {
    const query_id = webApp?.initDataUnsafe.query_id;
    if (!query_id) return;

    const payload = {
      driverId: webApp.initDataUnsafe.user.id.toString(),
      from,
      to,
      datetime: getISODateTime(date, time),
      passengersCount: +passangersCount,
      isThereAnimals: animalsAlowed,
      isThereKids: kidsAlowed,
      bagsCount: +bagsCount,
      description,
      price: +price,
    };

    const res = await createDrive(query_id, payload);

    setResult(res.data);
  };

  useEffect(() => {
    if (!tg) return;

    tg.MainButton.setParams({
      text: "Создать поездку",
    });
  }, [tg]);

  useEffect(() => {
    if (!tg) return;

    tg.MainButton.show();

    const currentDate = new Date();
    const dateAsObj = new Date(date);

    if (!from || !to || !passangersCount || price > recomendedCost || dateAsObj < currentDate) {
      tg.MainButton.setParams({
        color: "#8fc600",
      });
      tg.MainButton.disable();
    } else {
      tg.MainButton.enable();
      tg.MainButton.setParams({
        color: "#8fc600",
      });
    }
  }, [from, to, passangersCount, price, tg]);

  useEffect(() => {
    if (!tg) return;

    // @ts-ignore
    tg.onEvent("mainButtonClicked", onSendData);
    return () => {
      // @ts-ignore
      tg.offEvent("mainButtonClicked", onSendData);
    };
  }, [onSendData]);

  useEffect(() => {
    if (!from.length || !to.length) return;

    const route = all_routes.find((r) => {
      return (r.from == from && r.to == to) || (r.from == to && r.to == from)
    })
    if (route)
      setRecomendedCost(route.price)
  }, [from, to])

  return (
    <Box m="4" pt="4">
      <Card
        size="3"
        style={{
          contain: "unset",
          overflow: "unset",
          backgroundColor: lastStep >= 0 ? "var(--lime-a4)" : "",
        }}
        // onClick={() => setCurrentOpen(0)}
      >
        <Heading size="3">Создать поездку</Heading>
        <Flex
          mt="2"
          direction="column"
          gap="2"
          // display={currentOpen == 0 ? "flex" : "none"}
        >
          <Blockquote mb="2">
            <Flex direction="column" gap="2">
              <Combobox1
                getList={() => fromPlaces}
                placeholder="Откуда"
                value={from}
                arg={true}
                setValue={handleChangePlaces}
              />
              <Combobox1
                getList={() => toPlaces}
                placeholder="Куда"
                value={to}
                arg={false}
                setValue={handleChangePlaces}
              />
            </Flex>
          </Blockquote>
          <TextField.Root
            size="3"
            placeholder="Date"
            type="date"
            min={new Date().toISOString().slice(0, 10)}
            onChange={(e) => setDate(e.target.value)}
          />
          <TextField.Root
            size="3"
            defaultValue="00:00"
            type="time"
            onChange={(e) => setTime(e.target.value)}
          />
        </Flex>
      </Card>

      <Card
        mt="4"
        size="3"
        // onClick={() => (lastStep >= 0 ? setCurrentOpen(1) : "")}
        className={lastStep < 0 ? "disabled" : ""}
        style={{
          backgroundColor: lastStep >= 1 ? "var(--lime-a4)" : "",
        }}
      >
        <Heading size="3">Количество пасажиров</Heading>
        <Flex
          mt="2"
          direction="column"
          gap="4"
          // display={currentOpen == 1 ? "flex" : "none"}
        >
          <Flex direction="row" gap="4" justify="between">
            <Label>Число пассажиров</Label>
            <Select.Root size="3" onValueChange={(e) => setPassangersNumber(e)}>
              <Select.Trigger placeholder="Выберете" style={{ width: "50%" }} />
              <Select.Content>
                <Select.Item value="1">1</Select.Item>
                <Select.Item value="2">2</Select.Item>
                <Select.Item value="3">3</Select.Item>
                <Select.Item value="4">4</Select.Item>
                <Select.Item value="5">5</Select.Item>
              </Select.Content>
            </Select.Root>
          </Flex>

          <Callout.Root size="2">
            <Callout.Icon>
              <InfoCircledIcon />
            </Callout.Icon>
            <Callout.Text>
              <Text weight="bold">Помните:</Text> для пассажира важен комфорт.
              Рекомендуем оставлять сиденье посередине свободным.
            </Callout.Text>
          </Callout.Root>

          <Flex direction="row" gap="4" justify="between">
            <Label>Число сумок</Label>
            <Select.Root size="3" onValueChange={(e) => setBagsNumber(e)}>
              <Select.Trigger placeholder="Выберете" style={{ width: "50%" }} />
              <Select.Content>
                <Select.Item value="1">1</Select.Item>
                <Select.Item value="2">2</Select.Item>
                <Select.Item value="3">3</Select.Item>
                <Select.Item value="4">4</Select.Item>
                <Select.Item value="5">5</Select.Item>
              </Select.Content>
            </Select.Root>
          </Flex>

          <Flex direction="row" gap="4" justify="between">
            <Label>Можно с животными</Label>
            <Switch size="3" onCheckedChange={(e) => setAnimalsAlowed(e)} />
          </Flex>
          <Flex direction="row" gap="4" justify="between">
            <Label>Можно с детьми</Label>
            <Switch size="3" onCheckedChange={(e) => setKidsAlowed(e)} />
          </Flex>
          <TextArea
            size="3"
            placeholder="Описание"
            onChange={(e) => setDescription(e.target.value)}
          />
        </Flex>
      </Card>

      <Card
        mt="4"
        size="3"
        // onClick={() => (lastStep >= 0 ? setCurrentOpen(2) : "")}
        className={lastStep < 0 ? "disabled" : ""}
        style={{
          backgroundColor: lastStep >= 2 ? "var(--lime-a4)" : "",
        }}
      >
        <Heading size="3">Стоимость поездки</Heading>
        <Flex
          mt="2"
          direction="column"
          gap="4"
          // display={currentOpen == 2 ? "flex" : "none"}
        >
          <Box>
            <Text as="div" size="1" color="gray">
              Рекомендованная стоимость{" "}
              {/* <Tooltip onClick={() => setOpenTooltip(true)} content="Стоимость рассчитывается исходя из текущей цены топлива, дальности и длительности маршрута, а так же других параметров, используемых в алгоритме «Справедливая цена»">
                <InfoCircledIcon />
              </Tooltip> */}
            </Text>
            <Text style={{ color: "var(--accent-9)" }} weight="bold" size="5">
              {recomendedCost} TL{" "}
            </Text>
            <Text size="1" color="gray">
              {" "}
              / попутчик
            </Text>
          </Box>
          <Text size="2">
            Укажите стоимость поездки за одного пассажира в лирах
          </Text>
          <TextField.Root
            onChange={(e) => setPrice(Number(e.target.value))}
            size="3"
            placeholder="Введите стоимость"
            type="number"
          />

          {price / recomendedCost > 1.12 && (
            <Callout.Root size="2" color="red">
              <Callout.Icon>
                <ExclamationTriangleIcon />
              </Callout.Icon>
              <Callout.Text>Слишком высокая цена</Callout.Text>
            </Callout.Root>
          )}

          {price / recomendedCost < 0.5 && (
            <Callout.Root size="2" color="red">
              <Callout.Icon>
                <ExclamationTriangleIcon />
              </Callout.Icon>
              <Callout.Text>Слишком низкая цена</Callout.Text>
            </Callout.Root>
          )}

          {result?.error && (
          <Dialog.Root defaultOpen={true}>
            <Dialog.Trigger>
              <Button size="3" style={{display: "none"}}></Button>
            </Dialog.Trigger>
            <Dialog.Content>
              <Dialog.Title color="red">Ошибка</Dialog.Title>

              <Dialog.Description size="3">
                Проверьте количество открытых поездок /drives в чате бота - их может быть только 3
              </Dialog.Description>

              <Flex gap="3" mt="4" justify="end">
                <Dialog.Close>
                  <Button size="3" variant="solid">
                    ОК
                  </Button>
                </Dialog.Close>
              </Flex>
            </Dialog.Content>
          </Dialog.Root>
          )}
        </Flex>
      </Card>
    </Box>
  );
}
