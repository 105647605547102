import axios from "axios"

const API_URL = "https://api.poehali.pro"

export const createDrive = async (query_id: string, data: {
    driverId: string
    from: string   
    to: string   
    datetime: string
    passengersCount: number
    bagsCount: number
    isThereAnimals: boolean
    isThereKids: boolean
    description: string   
  }) => {
    return axios.post(`${API_URL}/create_drive?query_id=${query_id}`, data)
  }

export const getAllRoutes = async () => {
  return axios.get(`${API_URL}/all_routes`)
}

export const verifyUser = async (query_id: string, data: {
  userId: string
  name: string
  phoneNumber: string,
  email: string
}) => {
  return axios.post(`${API_URL}/verify_user?query_id=${query_id}`, data)
}

export const searchDrives = async (from: string, to: string, date: string) => {
  return axios.get(`${API_URL}/drives/search/${from}/${to}/${date}`)
}

export const joinDrive = async (query_id: string, data: {
  userId: string
  driveId: number
}) => {
  return axios.post(`${API_URL}/join_drive?query_id=${query_id}`, data)
}

export const createPassangerDriverRequest = async (query_id: string, data: {
  userId: string
  from: string   
  to: string   
  datetime: string
}) => {
  return axios.post(`${API_URL}/create_passanger_req?query_id=${query_id}`, data)
}

export const createNotifySignRequest = async (query_id: string, data: {
  userId: string
  from: string   
  to: string   
  datetime: string
  isDateRange: boolean
  dateFrom?: string
  dateTo?: string
}) => {
  return axios.post(`${API_URL}/create_notify_sign_req?query_id=${query_id}`, data)
}


export const createTransferBotRequest = async (query_id: string, data: {
  userId: string
}) => {
  return axios.post(`${API_URL}/create_transferbot_req?query_id=${query_id}`, data)
}